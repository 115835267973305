<template>
  <v-row>
    <v-col cols="12">
      <v-list>
        <v-row>
          <v-col cols="6" v-for="(item, i) in items" :key="i">
            <v-list-item :to="item.url">
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "All Journal",
        url: "/report/all-journal",
        icon: "mdi-note",
      },
      {
        title: "Laporan Laba Rugi",
        url: "/report/laba-rugi",
        icon: "mdi-note",
      },
      {
        title: "Neraca",
        url: "/report/neraca",
        icon: "mdi-note",
      },
      {
        title: "Cash flow",
        url: "/report/cash-flow",
        icon: "mdi-note",
      },
      // {
      //   title: "Perubahan Ekuitas",
      //   url: "/report/perubahan-ekuitas",
      //   icon: "mdi-note",
      // },
      {
        title: "General Ledger",
        url: "/report/general-ledger",
        icon: "mdi-note",
      },
      {
        title: "Trial Balance",
        url: "/report/trial-balance",
        icon: "mdi-note",
      },
      // {
      //   title: "Rasio",
      //   url: "/report/rasio",
      //   icon: "mdi-note",
      // },
      // {
      //   title: "AR Outstanding Detail",
      //   url: "/report/ar-outstanding/detail",
      //   icon: "mdi-note",
      // },
      {
        title: "AR",
        url: "/report/ar-outstanding/summary",
        icon: "mdi-note",
      },
      {
        title: "AR Summary",
        url: "/report/ar/summary",
        icon: "mdi-note",
      },
      // {
      //   title: "Kartu Harga Pokok Produksi",
      //   url: "/report/khpp",
      //   icon: "mdi-note",
      // },
      // {
      //   title: "Laporan Harga Pokok Produksi",
      //   url: "/report/harga-pokok/produksi",
      //   icon: "mdi-note",
      // },
      // {
      //   title: "Laporan Harga Pokok Penjualan",
      //   url: "/report/harga-pokok/penjualan",
      //   icon: "mdi-note",
      // },
      // {
      //   title: "Laporan Biaya Jasa",
      //   url: "/report/biaya-jasa",
      //   icon: "mdi-note",
      // },
      // {
      //   title: "AP Outstanding Detail",
      //   url: "/report/ap-outstanding/detail",
      //   icon: "mdi-note",
      // },
      {
        title: "AP",
        url: "/report/ap-outstanding/summary",
        icon: "mdi-note",
      },
      // {
      //   title: "Other AP Detail",
      //   url: "/report/other-ap/detail",
      //   icon: "mdi-note",
      // },
      {
        title: "Other AP",
        url: "/report/other-ap/summary",
        icon: "mdi-note",
      },
      // {
      //   title: "Daftar Aktiva Tetap",
      //   url: "/report/daftar-aktiva-tetap",
      //   icon: "mdi-note",
      // },
      // {
      //   title: "Daftar Biaya Dibayar Dimuka",
      //   url: "/report/biaya-dibayar-dimuka",
      //   icon: "mdi-note",
      // },
      // {
      //   title: "Persamaan PO",
      //   url: "/report/persamaan-po",
      //   icon: "mdi-note",
      // },
      {
        title: "Nomor Seri Faktur Pajak",
        url: "/report/nomor-seri/faktur-pajak",
        icon: "mdi-note",
      },
      {
        title: "PPN",
        url: "/report/ppn",
        icon: "mdi-note",
      },
      {
        title: "PPH",
        url: "/report/pph",
        icon: "mdi-note",
      },
      {
        title: "Export Daftar Pajak",
        url: "/report/export-daftar-pajak",
        icon: "mdi-note",
      },
      {
        title: "Tanda Terima",
        url: "/report/tanda-terima",
        icon: "mdi-note",
      },
      {
        title: "Payment",
        url: "/report/Payment",
        icon: "mdi-note",
      },
      {
        title: "Sales",
        url: "/report/sales",
        icon: "mdi-note",
      },
      {
        title: "Bank Statement",
        url: "/report/bank-statement",
        icon: "mdi-note",
      },
      {
        title: "Bank Guarantee",
        url: "/report/bank-guarantee",
        icon: "mdi-note",
      },
      {
        title: "Kasbon Installer",
        url: "/report/kasbon-installer",
        icon: "mdi-note",
      },
      {
        title: "Kasbon Outstanding",
        url: "/report/kasbon-outstanding",
        icon: "mdi-note",
      },
      {
        title: "Settlement Kasbon",
        url: "/report/settlement-kasbon",
        icon: "mdi-note",
      },
      {
        title: "Petty Cash",
        url: "/report/petty-cash",
        icon: "mdi-note",
      },
      {
        title: "Depreciation",
        url: "/report/depreciation",
        icon: "mdi-note",
      },
      {
        title: "Payment Sale Down Payment",
        url: "/report/payment/sale-down-payment",
        icon: "mdi-note",
      },
      {
        title: "Currency",
        url: "/report/currency",
        icon: "mdi-note",
      },
      {
        title: "Sale Return",
        url: "/report/sale/return",
        icon: "mdi-note",
      },
      {
        title: "Cash Budget",
        url: "/report/cash-budget",
        icon: "mdi-note",
      },
      {
        title: "Reval",
        url: "/report/reval",
        icon: "mdi-note",
      },
      {
        title: "Journal",
        url: "/report/journals",
        icon: "mdi-note",
      },
      {
        title: "COGS",
        url: "/report/cogs",
        icon: "mdi-note",
      },
      {
        title: "COGM",
        url: "/report/cogm",
        icon: "mdi-note",
      },
      {
        title: "Begin Balance",
        url: "/report/begin-balance",
        icon: "mdi-note",
      },
      {
        title: "Income Statement Monthly",
        url: "/report/income-statement/monthly",
        icon: "mdi-note",
      },
      {
        title: "Revenue Yearly",
        url: "/report/revenue/yearly",
        icon: "mdi-note",
      },
      {
        title: "Working Capital",
        url: "/report/working-capital",
        icon: "mdi-note",
      },
      {
        title: "AP Tax",
        url: "/report/account-payable/tax",
        icon: "mdi-note",
      },
      {
        title: "Summary Settlement Kasbon",
        url: "/report/summary/settlement-kasbon",
        icon: "mdi-note",
      },
      {
        title: "Summary Kasbon",
        url: "/report/summary/kasbon",
        icon: "mdi-note",
      },
      {
        title: "Revaluation",
        url: "/report/revaluation",
        icon: "mdi-note",
      },
      {
        title: "BOM Detaiil",
        url: "/report/bom/detail",
        icon: "mdi-note",
      },
      {
        title: "Transaction Inventory",
        url: "/report/inventory/transaction",
        icon: "mdi-note",
      },
      {
        title: "Transaction RR",
        url: "/report/inventory/transaction/rr",
        icon: "mdi-note",
      },
      {
        title: "Transaction Inventory Summary",
        url: "/report/inventory/transaction/summary",
        icon: "mdi-note",
      },
      {
        title: "Inventory Aging",
        url: "/report/inventory/aging",
        icon: "mdi-note",
      },
      {
        title: "Inventory Aging Summary",
        url: "/report/inventory/aging/summary",
        icon: "mdi-note",
      },
      {
        title: "Transaction MR",
        url: "/report/inventory/mr",
        icon: "mdi-note",
      },
      {
        title: "Transaction HO",
        url: "/report/inventory/ho",
        icon: "mdi-note",
      },
      {
        title: "Tanda Terima Kasbon",
        url: "/report/tanda-terima/kasbon",
        icon: "mdi-note",
      },
      {
        title: "Expense Kasbon",
        url: "/report/expense/kasbon",
        icon: "mdi-note",
      },
    ],
  }),
  methods: {
    routeToURL() {
      this.$store.dispatch("report/getAllJournal", {
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
  },
};
</script>

<style></style>
